export const useClassifiedDataManager = () => {
    const route = useRoute();
    const classifiedStore = useClassified();
    const classifiedDataService = useClassifiedDataService();

     /* Resources */
     const getClassifiedAdCategories = async () => {
        const storedClassifiedAdCategories = computed(() => {
            return classifiedStore.classified_ad_categories;
        })

        if (storedClassifiedAdCategories.value == null || storedClassifiedAdCategories.value.length == 0 || !classifiedStore.isClassifiedAdCategoriesValid()) {
            const classifiedAdCategories = await classifiedDataService.fetchClassifiedAdCategories();
            if(classifiedAdCategories)
            classifiedStore.setClassifiedAdCategories(classifiedAdCategories);
        }

        return storedClassifiedAdCategories;
    }

    const getClassifiedAdItemConditions = async () => {
        const storedClassifiedAdItemConditions = computed(() => {
            return classifiedStore.classified_ad_item_conditions;
        })
        if (storedClassifiedAdItemConditions.value == null || storedClassifiedAdItemConditions.value.length == 0 || !classifiedStore.isClassifiedAdItemConditionsValid()) {
           const classifiedAdItemConditions =  await classifiedDataService.fetchClassifiedAdItemConditions();
           if(classifiedAdItemConditions)
           classifiedStore.setClassifiedAdItemConditions(classifiedAdItemConditions);
        }

        return storedClassifiedAdItemConditions;
    }

    const getClassifiedAdQuota = async () => {
        const storedClassifiedAdQuota = computed(() => {
            return classifiedStore.classified_ad_quota;
        })
        if (storedClassifiedAdQuota.value == null || !classifiedStore.isClassifiedAdQuotaValid()) {
           const classifiedAdQuota = await classifiedDataService.fetchClassifiedAdQuota();
           if(classifiedAdQuota) 
           classifiedStore.setClassifiedAdQuota(classifiedAdQuota);
        }

        return storedClassifiedAdQuota.value;
    }

    const getClassifiedAdFeaturedPackages = async () => {
        const storedClassifiedAdFeaturedPackages = computed(() => {
            return classifiedStore.classified_ad_featured_packages;
        })
        if (storedClassifiedAdFeaturedPackages.value == null || storedClassifiedAdFeaturedPackages.value.length == 0 || !classifiedStore.isClassifiedAdFeaturedPackagesValid()) {
            const classifiedAdFeaturedPackages = await classifiedDataService.fetchClassifiedAdFeaturedPackages();
            if(classifiedAdFeaturedPackages)
            classifiedStore.setClassifiedAdFeaturedPackages(classifiedAdFeaturedPackages);
        }

        return storedClassifiedAdFeaturedPackages;
    }
    /* Resources End */

    /* Classified Dashboard Data */
    
    const getDashboardClassifiedAdsByFilter = async () => {
        const dashboardData = await classifiedDataService.fetchClassifiedAdsDataForDashboardByFilter();
        return computed(() => dashboardData?.value)
    }

    const getDashboardClassifiedAdsFromUrl = async (url: string) => {
        const results = await classifiedDataService.fetchClassifiedAdsDataForDashboardFromUrl(url);
        useScrollManager().scrollToTop();
        return computed(() => results)
    }

    /* Classified Dashboard Data End */

    /* Dashboard Classified Ad */
    const getCurrentDashboardClassifiedAd = async () => {
        const storedClassifiedAd = computed(() => {
            return classifiedStore.current_dashboard_classified_ad;
        })

        const slug = route.params.slug;
        if ((storedClassifiedAd.value == null || storedClassifiedAd.value.id == null || storedClassifiedAd.value.slug != route.params.slug || !classifiedStore.isCurrentDashboardClassifiedAdValid()) && slug) {
            const classifiedAd = await classifiedDataService.fetchClassifiedAdResourceForDashboard(slug as string);
            if(classifiedAd)
            classifiedStore.setCurrentDashboardClassifiedAd(classifiedAd);
        }

        return storedClassifiedAd;
    }

    const createNewClassifiedAd = async (data: object) => {
        const createdClassifiedAd = await classifiedDataService.requestToCreateNewClassifiedAd(data);
        if (createdClassifiedAd) {
            classifiedStore.setCurrentDashboardClassifiedAd(createdClassifiedAd);
            useAlertSetter().setSuccessAlert("Classified Listing Created Successfully");
            navigateTo({
                name: "dashboard-classified",
                query: {
                    filter: "active",
                }
            });
        }
    };

    const updateClassifiedAd = async (data: object) => {
        const updatedClassifiedAd = await classifiedDataService.requestToUpdateClassifiedAd(data);
        if(updatedClassifiedAd){
            classifiedStore.setCurrentDashboardClassifiedAd(updatedClassifiedAd);
            useAlertSetter().setSuccessAlert("Classified Listing Updated Successfully");
            navigateTo({
                name: "dashboard-classified-slug",
                params: { slug: classifiedStore.current_dashboard_classified_ad?.slug as string },
            })
        }
    };

    const deactivateCurrentDashboardClassifiedAd = async () => {
        const deactivatedClassifiedAd = await classifiedDataService.requestToDeactivateCurrentDashboardClassifiedAd();
        if(deactivatedClassifiedAd){
            classifiedStore.setCurrentDashboardClassifiedAd(deactivatedClassifiedAd)
            navigateTo({
                name: "dashboard-classified-slug",
                params: {
                    slug: deactivatedClassifiedAd.slug
                }
            });
        }
    };

    const submitCurrentDashboardClassifiedAdForApproval = async () => {
        const submittedClassifiedAd = await classifiedDataService.requestToSubmitCurrentDashboardClassifiedAdForApproval();
        if(submittedClassifiedAd){
            classifiedStore.setCurrentDashboardClassifiedAd(submittedClassifiedAd);
            navigateTo({
              name: "dashboard-classified-slug",
              params: {
                slug: submittedClassifiedAd.slug,
              },
            });
        }
    };
    /* Dashboard Classified Ad End*/

    /*Dashboard Classified Ad Images*/
    const getCurrentDashboardClassifiedAdImages = async () => {
        const storedClassifiedAdImages = computed(() => {
            return classifiedStore.current_dashboard_classified_ad_images;
        })
        if (storedClassifiedAdImages.value == null || storedClassifiedAdImages.value.length == 0 || !classifiedStore.isCurrentDashboardClassifiedAdImagesValid()) {
            const images = await classifiedDataService.fetchCurrentDashboardClassifiedAdImages();
            if(images)
            classifiedStore.setCurrentDashboardClassifiedAdImages(images);
        }
        return storedClassifiedAdImages;
    }

    const addImageToCurrentDashboardClassifiedAdImages = (imageUrl: string) => {
        const storedClassifiedAdImages = computed(() => {
            return classifiedStore.current_dashboard_classified_ad_images;
        })
        classifiedStore.addImageToCurrentDashboardClassifiedAdImages(imageUrl);
        useAlertSetter().setSuccessAlert("Successfully added image for classified listing");
        return storedClassifiedAdImages;
    }

    const removeImageFromCurrentDashboardClassifiedAdImagesAtIndex = async (index: number) => {
        const storedClassifiedAdImages = computed(() => {
            return classifiedStore.current_dashboard_classified_ad_images;
        })
        const results = await classifiedDataService.requestToDeleteCurrentDashboardClassifiedAdImageByIndex(index);
        if(results)
        classifiedStore.removeImageFromCurrentDashboardClassifiedAdImagesAtIndex(index);

        useAlertSetter().setSuccessAlert("Successfully removed image from classified listing");
        return storedClassifiedAdImages;
    }
    /*Dashboard Classified Ad Images End*/

    /*Dashboard Classified Ad Orders*/
    const getCurrentDashboardClassifiedAdOrders = async () => {
        const storedClassifiedAdOrders = computed(() => {
            return classifiedStore.current_dashboard_classified_ad_orders;
        })
        if (storedClassifiedAdOrders.value == null || storedClassifiedAdOrders.value.length == 0 || !classifiedStore.isCurrentDashboardClassifiedAdOrdersValid()) {
            const classifiedAdOrders = await classifiedDataService.fetchCurrentDashboardClassifiedAdFeaturedPackageOrders();
            if(classifiedAdOrders)
            classifiedStore.setCurrentDashboardClassifiedAdOrders(classifiedAdOrders);
        }
        return storedClassifiedAdOrders;
    }

    const createNewClassifiedAdOrder = async (formData: object) => {
        const createdClassifiedAdOrder = await classifiedDataService.submitFormToCreateNewClassifiedAdFeaturedPackageOrder(formData);
        if(createdClassifiedAdOrder){
            classifiedStore.addOrderToCurrentDashboardClassifiedAdOrders(createdClassifiedAdOrder)
            useAlertSetter().setSuccessAlert("Successfully Purchased Order!")
            classifiedStore.updateFeaturedUntilDateOfCurrentDashboardClassifiedAd(createdClassifiedAdOrder.order_for_featured_until, createdClassifiedAdOrder.order_for_featured_until_formatted);
        }
        return createdClassifiedAdOrder;
    };

    const refundClassifiedAdOrder = async (orderId: number) => {
        const refundedClassifiedAdOrder = await classifiedDataService.requestToRefundClassifiedAdFeaturedPackageOrderByOrderId(orderId);
        if(refundedClassifiedAdOrder){
            useAlertSetter().setSuccessAlert("Successfully Refunded Order! Your refunded amount will appear in your account within 3-5 business days.");
            classifiedStore.updateFeaturedUntilDateOfCurrentDashboardClassifiedAd(refundedClassifiedAdOrder.order_for_featured_until, refundedClassifiedAdOrder.order_for_featured_until_formatted);
            classifiedStore.updateOrderOfCurrentDashboardClassifiedAd(refundedClassifiedAdOrder);
        }
    };
    /*Dashboard Classified Ad Orders Ends*/

    /*Save Classified Ad*/
    const getDashboardSavedClassifiedAds = async () => {
        const dashboardData = await classifiedDataService.fetchSavedClassifiedAdsDataForDashboard();
        return computed(() => dashboardData?.value)
    }

    const getDashboardSavedClassifiedAdsFromUrl = async (url: string) => {
        const results = await classifiedDataService.fetchSavedClassifiedAdsDataForDashboardFromUrl(url);
        useScrollManager().scrollToTop();
        return computed(() => results)
    }

    const unSaveClassifiedAd = async (slug: string) => {

        const unSavedClassifiedAd = await classifiedDataService.requestToUnSaveClassifiedAd(slug);
        if(unSavedClassifiedAd){
            useAlertSetter().setSuccessAlert("Successfully Un Saved Classified Ad.");
            return unSavedClassifiedAd;
        }
        return null;
    };
    /*Save Classified Ad Ends*/

    return {
        getClassifiedAdCategories,
        getClassifiedAdItemConditions,
        getClassifiedAdQuota,
        getClassifiedAdFeaturedPackages,
        
        getDashboardClassifiedAdsByFilter,
        getDashboardClassifiedAdsFromUrl,

        getCurrentDashboardClassifiedAd,
        createNewClassifiedAd,
        updateClassifiedAd,
        deactivateCurrentDashboardClassifiedAd,
        submitCurrentDashboardClassifiedAdForApproval,
        
        getCurrentDashboardClassifiedAdImages,
        addImageToCurrentDashboardClassifiedAdImages,
        removeImageFromCurrentDashboardClassifiedAdImagesAtIndex,

        getDashboardSavedClassifiedAds,
        getDashboardSavedClassifiedAdsFromUrl,
        unSaveClassifiedAd,

        getCurrentDashboardClassifiedAdOrders,
        createNewClassifiedAdOrder,
        refundClassifiedAdOrder,
    }
}

