import type {
    ClassifiedAd,
    ClassifiedAdCategory,
    ClassifiedAdFeaturedPackage,
    ClassifiedAdFeaturedPackageOrder,
    ClassifiedAdItemCondition, ClassifiedAdList,
    ClassifiedAdQuota,
    DashboardClassifiedAd,
    DashboardClassifiedAdData, SavedClassifiedAd, SavedClassifiedAdsData
} from "~/types";

export const useClassifiedDataService = () => {
    const nuxtApp = useNuxtApp();
    const route = useRoute();

    /* Resources */
    const fetchClassifiedAdCategories = async () => {
        const {data, error} = await useAsyncData('classified-ad-categories',
            () => nuxtApp.$getClassifiedAdCategories()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as ClassifiedAdCategory[];
        }
    }

    const fetchClassifiedAdItemConditions = async () => {
        const {data, error} = await useAsyncData('classified-ad-item-conditions',
            () => nuxtApp.$getClassifiedAdItemConditions()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as ClassifiedAdItemCondition[];
        }
    }

    const fetchClassifiedAdFeaturedPackages = async () => {
        const {data, error} = await useAsyncData('classified-ad-featured-packages',
            () => nuxtApp.$getClassifiedAdFeaturedPackages()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as ClassifiedAdFeaturedPackage[];
        }
    }

    const fetchClassifiedAdQuota = async () => {
        const {data, error} = await useAsyncData('classified-ad-quota',
            () => nuxtApp.$getClassifiedAdQuota()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as ClassifiedAdQuota[];
        }
    }
    /* Resources End*/

    /* Classified Dashboard Data */
    const fetchClassifiedAdsDataForDashboardByFilter = async () => {
        const {data, error, refresh} = await useAsyncData(`${route.query.filter ?? 'active'}-classified-ads`,
            () => useNuxtApp().$getMyClassifiedAdsByFilter((route.query.filter as string) ?? 'active')
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            const filteredClassifiedAdsData = computed<DashboardClassifiedAdData>(() => {
                return {
                    classified_ads: data.value.data.data,
                    pagination_meta_data: data.value.data.meta,
                    pagination_links: data.value.data.links,
                    statistics: data.value.statistics,
                }
            });

            return ref({
                filteredClassifiedAdsData,
                refresh
            });
        }
    }

    const fetchClassifiedAdsDataForDashboardFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getClassifiedAdsDataFromUrl(url);
            const classifiedAdsData = {
                classified_ads: response.data.data,
                pagination_meta_data: response.data.meta,
                pagination_links: response.data.links,
                statistics: response.statistics,
            } as DashboardClassifiedAdData;

            return classifiedAdsData;

        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    };
    /* Classified Dashboard Data End */

    /* Dashboard Classified Ad */
    const fetchClassifiedAdResourceForDashboard = async (slug: string) => {
        const {data, error} = await useAsyncData('dashboard-classified-ad',
            () => nuxtApp.$getMyClassifiedAdBySlug(slug)
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value?.data as DashboardClassifiedAd
        }
    };

    const requestToCreateNewClassifiedAd = async (data: object) => {
        try {
            const response = await nuxtApp.$createClassifiedAd(data);
            return response.data as DashboardClassifiedAd;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateClassifiedAd = async (data: object) => {
        try {
            const response = await nuxtApp.$updateClassifiedAdBySlug(route.params.slug as string, data);
            return response.data as DashboardClassifiedAd;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToDeactivateCurrentDashboardClassifiedAd = async () => {
        try {
            const response = await nuxtApp.$deactivateMyClassifiedAdBySlug(route.params.slug as string);
            return response.data as DashboardClassifiedAd;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToSubmitCurrentDashboardClassifiedAdForApproval = async () => {
        try {
            const response = await nuxtApp.$submitMyClassifiedAdForApprovalBySlug(route.params.slug as string);
            return response.data as DashboardClassifiedAd;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    /* Dashboard Classified Ad End*/

    /*Dashboard Saved Classified Ads*/
    const fetchSavedClassifiedAdsDataForDashboard = async () => {
        const {data, error, refresh} = await useAsyncData(`saved-classified-ads`,
            () => useNuxtApp().$getMySavedClassifiedAds()
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return computed<SavedClassifiedAdsData>(()=>{
                return {
                    classified_ads: data.value.data as SavedClassifiedAd[],
                    pagination_meta_data: data.value.meta as PaginationMeta,
                    pagination_links: data.value.links as PaginationLinks,
                }
            });
        }
    }

    const fetchSavedClassifiedAdsDataForDashboardFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getClassifiedAdsDataFromUrl(url);
            return {
                classified_ads: response.data.data,
                pagination_meta_data: response.data.meta,
                pagination_links: response.data.links,
            } as SavedClassifiedAdsData;

        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    };

    const requestToUnSaveClassifiedAd = async (slug:string) => {
        try {
            const response = await nuxtApp.$unSaveClassifiedAdBySlug(slug);
            return response.data as ClassifiedAd;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }
    /*Dashboard Saved Classified Ads* End/
   /*Dashboard Classified Ad Images*/
    const fetchCurrentDashboardClassifiedAdImages = async () => {
        const {data, error} = await useAsyncData('dashboard-classified-ad-images',
            () => nuxtApp.$getMyClassifiedAdImagesByClassifiedAdSlug(route.params.slug as string)
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value?.data as string[];
        }
    };

    const requestToDeleteCurrentDashboardClassifiedAdImageByIndex = async (index: number) => {
        try {
            const response = await nuxtApp.$deleteMyClassifiedAdImageByClassifiedAdImageIndex(route.params.slug as string, index);
            return response.data.message as string;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }
    /*Dashboard Classified Ad Images End*/

    /*Dashboard Classified Ad Orders*/
    const fetchCurrentDashboardClassifiedAdFeaturedPackageOrders = async () => {
        const {data, error} = await useAsyncData('dashboard-classified-ad-orders',
            () => nuxtApp.$getMyClassifiedAdOrdersByClassifiedAdSlug(route.params.slug as string)
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value?.data as ClassifiedAdFeaturedPackageOrder[];
        }
    };

    const submitFormToCreateNewClassifiedAdFeaturedPackageOrder = async (newFeaturedPackageOrderForm: object) => {
        try {
            const response = await nuxtApp.$createOrderForMyClassifiedAdBySlug(route.params.slug as string, newFeaturedPackageOrderForm)
            return response.data as ClassifiedAdFeaturedPackageOrder;
        } catch (error: any) {
            setError(error.data as ApiError)
            return null;
        }
    }

    const requestToRefundClassifiedAdFeaturedPackageOrderByOrderId = async (orderId: number) => {
        try {
            const response = await nuxtApp.$refundOrderForMyClassifiedAd(route.params.slug as string, orderId);
            return response.data as ClassifiedAdFeaturedPackageOrder;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    /*Dashboard Classified Ad Orders End*/

    function setError(error: ApiError) {
        useAlertSetter().setErrorAlert(error);
    }

    return {
        fetchClassifiedAdCategories,
        fetchClassifiedAdItemConditions,
        fetchClassifiedAdFeaturedPackages,
        fetchClassifiedAdQuota,

        fetchClassifiedAdsDataForDashboardByFilter,
        fetchClassifiedAdsDataForDashboardFromUrl,

        fetchClassifiedAdResourceForDashboard,
        requestToCreateNewClassifiedAd,
        requestToUpdateClassifiedAd,
        requestToDeactivateCurrentDashboardClassifiedAd,
        requestToSubmitCurrentDashboardClassifiedAdForApproval,

        fetchCurrentDashboardClassifiedAdImages,
        requestToDeleteCurrentDashboardClassifiedAdImageByIndex,

        fetchSavedClassifiedAdsDataForDashboard,
        fetchSavedClassifiedAdsDataForDashboardFromUrl,
        requestToUnSaveClassifiedAd,

        fetchCurrentDashboardClassifiedAdFeaturedPackageOrders,
        submitFormToCreateNewClassifiedAdFeaturedPackageOrder,
        requestToRefundClassifiedAdFeaturedPackageOrderByOrderId,
    }
}